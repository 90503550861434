import React from "react"
import { Col, Row } from "reactstrap"
import Layout from "../../components/layout"
import NewSocial from "./_NewSocial"

export const Head = () => {
  return <title>Manage Social</title>
}

const ManageSocial = ({ location }) => {
  return (
    <div>
      <Layout title={"Add New Social"} progress={false}>
        <Row>
          <Col className={""} sm={12}>
            <NewSocial editItem={location?.state?.social ?? null} />
          </Col>
        </Row>
      </Layout>
    </div>
  )
}

export default ManageSocial
