import React, { useEffect, useState } from "react"
import { Button, Col, FormGroup, Input, Row } from "reactstrap"
import { Formik } from "formik"
import FormikInput from "../../components/Inputs/FormikInput"
import { socialFormInitialValues, socialFormSchema } from "./_data"
import MeetupDropzone from "../../templates/EditSpace/TabContent/OnDemandWorkspace/Sections/Meetups/MeetupDropzone"
import { useAuth } from "../../auth/useAuth"
import isDev from "../../utils/isDev"
import { Link, navigate } from "gatsby"
import Datepicker from "react-datepicker"
import { addMinutes, format, isValid, startOfDay } from "date-fns"
import { getUtcDateTime } from "../../utils/getUtcDateTime"
import { formatInTimeZone } from "date-fns-tz"

const NewSocial = ({ editItem = null }) => {
  const { makeRequest } = useAuth()

  return (
    <div>
      <Link to={"/socials"} className={"btn btn-sm btn-light"}>
        Back
      </Link>
      <div className={"mt-4 col-sm-6"}>
        <Formik
          initialValues={socialFormInitialValues(editItem)}
          validationSchema={socialFormSchema}
          validateOnMount={false}
          onSubmit={(values, { resetForm, setSubmitting }) => {
            const { startDateTime, endDateTime } = values

            const start = new Date(startDateTime)
            const end = new Date(endDateTime)

            let data = {}

            if (editItem?.id) {
              data = {
                title: values?.title || null,
                imageUrl: values?.imageUrl || null,
                description: values?.description || null,
                rsvpUrl: values?.rsvpUrl || null,
              }
            } else {
              data = {
                ...values,
                date: format(values.date, "yyyy-MM-dd"),
                startDateTime: getUtcDateTime(
                  start,
                  format(start, "hh:mm a")
                ).toISOString(),
                endDateTime: getUtcDateTime(
                  end,
                  format(end, "hh:mm a")
                ).toISOString(),
              }
            }

            if (!start || !end) return
            makeRequest(
              "socials",
              {
                data,
                id: editItem?.id ?? null,
              },
              "post"
            )
              .then((res) => {
                if (!isDev) {
                  resetForm()
                }
              })
              .catch((e) => {
                console.log(e)
              })
              .finally(() => {
                setSubmitting(false)
                navigate("/socials")
              })
          }}
        >
          {({
            isSubmitting,
            handleSubmit,
            setFieldValue,
            values,
            errors,
            touched,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <FormikInput name={"title"} label={"Title"} />

                <label>Cover Image</label>
                <MeetupDropzone
                  imageUrl={values.imageUrl}
                  setImageUrl={(value) => setFieldValue("imageUrl", value)}
                  makeRequest={makeRequest}
                />

                <FormikInput
                  name={"description"}
                  type={"textarea"}
                  label={"Description"}
                />

                {/*<FormikInput name={"cost"} label={"Cost (Optional)"} />*/}

                {/*<FormikInput*/}
                {/*  name={"numberOfSpots"}*/}
                {/*  label={"Number of Spots (Optional)"}*/}
                {/*  type={"number"}*/}
                {/*/>*/}

                <FormGroup>
                  <label htmlFor={"date"}>Date</label>
                  {!!editItem?.date ? (
                    <p>
                      {formatInTimeZone(
                        new Date(editItem.startDateTime),
                        "America/Toronto",
                        "yyyy MMM dd, h:mm a"
                      )}{" "}
                      -{" "}
                      {formatInTimeZone(
                        new Date(editItem.endDateTime),
                        "America/Toronto",
                        "h:mm a"
                      )}
                    </p>
                  ) : (
                    <Datepicker
                      selected={values?.date ? new Date(values.date) : null}
                      name={"date"}
                      id={"date"}
                      minDate={new Date()}
                      onSelect={(date) =>
                        setFieldValue("date", startOfDay(date))
                      }
                      className={"form-control"}
                      dateFormat={"yyyy MMMM dd"}
                    />
                  )}
                </FormGroup>

                {!editItem?.date && (
                  <Row>
                    <Col>
                      <FormikInput
                        name={"startDateTime"}
                        label={"Start Time"}
                        type={"select"}
                        options={getTimes(values.date, 1)}
                      />
                    </Col>
                    <Col>
                      <FormikInput
                        name={"endDateTime"}
                        label={"End Time"}
                        type={"select"}
                        options={getTimes(values.date, 2)}
                      />
                    </Col>
                  </Row>
                )}

                {/*<GooglePlacesSearchBox*/}
                {/*  value={values?.address}*/}
                {/*  onChange={handleAddress}*/}
                {/*  onCustomStreetView={() => null}*/}
                {/*  cols={12}*/}
                {/*  hasError={errors.organizer && touched?.address}*/}
                {/*/>*/}

                {/*<FormikInput*/}
                {/*  name={"thingsToConsider"}*/}
                {/*  type={"textarea"}*/}
                {/*  label={"Things to consider"}*/}
                {/*/>*/}

                {/*<FormikInput name={"organizer"} label={"Organizer"} />*/}

                {/*<label>Organizer Photo</label>*/}
                {/*<MeetupDropzone*/}
                {/*  imageUrl={values.organizerPhotoUrl}*/}
                {/*  setImageUrl={(value) =>*/}
                {/*    setFieldValue("organizerPhotoUrl", value)*/}
                {/*  }*/}
                {/*  options={{*/}
                {/*    maxHeight: 512,*/}
                {/*    maxWidth: 512,*/}
                {/*  }}*/}
                {/*/>*/}

                {/*<FormikInput name={"type"} label={"Type (Optional)"} />*/}

                <FormikInput name={"rsvpUrl"} label={"RSVP URL"} />
                <div className="d-flex justify-content-between">
                  <Button
                    color="secondary"
                    size="sm"
                    onClick={() => navigate("/socials")}
                  >
                    Back
                  </Button>
                  <Button
                    color="primary"
                    size="sm"
                    type={"submit"}
                    disabled={isSubmitting}
                  >
                    {!!editItem ? "Save" : "Add"}
                  </Button>
                </div>
              </form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export default NewSocial

const getTimes = (date, startHour = 8, slots = 44) => {
  const times = [{ label: "Please select", value: "" }]

  if (!date || !isValid(date)) {
    return times
  }

  for (let i = 0; i < slots; i++) {
    const d = addMinutes(date, (startHour + startHour + i) * 30)
    times.push({
      label: format(d, "hh:mm a"),
      value: d.toISOString(),
    })
  }

  return times
}
