import * as Yup from "yup"
import { init } from "@sentry/browser"

export const socialFormInitialValues = (initialValues = {}) => {
  return {
    title: initialValues?.title ?? null,
    imageUrl: initialValues?.imageUrl ?? null,
    description: initialValues?.description ?? null,
    cost: initialValues?.cost ?? null,
    numberOfSpots: initialValues?.numberOfSpots ?? null,
    date: initialValues?.date ? new Date(initialValues?.date) : null,
    startDateTime: initialValues?.startDateTime ?? null,
    endDateTime: initialValues?.endDateTime ?? null,
    thingsToConsider: initialValues?.thingsToConsider ?? null,
    address: initialValues?.address ?? null,
    placeId: initialValues?.placeId ?? null,
    lat: initialValues?.lat ?? null,
    lng: initialValues?.lng ?? null,
    organizer: initialValues?.organizer ?? null,
    organizerPhotoUrl: initialValues?.organizerPhotoUrl ?? null,
    type: initialValues?.type ?? null,
    rsvpUrl: initialValues?.rsvpUrl ?? null,
  }
}

export const socialFormSchema = Yup.object().shape({
  title: Yup.string().required("Required"),
  imageUrl: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
  cost: Yup.string().nullable(),
  thingsToConsider: Yup.string().nullable(),
  numberOfSpots: Yup.number().nullable(),
  date: Yup.string().required("Required"),
  startDateTime: Yup.string().required("Required"),
  endDateTime: Yup.string().required("Required"),
  address: Yup.string().nullable(),
  shortAddress: Yup.string().nullable(),
  placeId: Yup.string().nullable(),
  lat: Yup.number().nullable(),
  lng: Yup.number().nullable(),
  organizer: Yup.string().nullable(),
  organizerPhotoUrl: Yup.string().nullable(),
  type: Yup.string().nullable(),
  rsvpUrl: Yup.string().required("Required"),
})
