import React from "react"
import { useFormikContext } from "formik"
import { FormGroup, Input } from "reactstrap"

const FormikInput = ({
  name,
  label,
  type = "text",
  options = [],
  helpText = "",
}) => {
  const formik = useFormikContext()

  return (
    <FormGroup>
      <label htmlFor={name}>{label}</label>
      {helpText && (
        <span className={"text-muted d-block mb-1 mt-0"}>{helpText}</span>
      )}
      <Input
        type={type}
        id={name}
        value={formik.values?.[name] ?? ""}
        onChange={formik.handleChange}
        className={
          formik.touched?.[name] && formik.errors?.[name] ? "border-danger" : ""
        }
        name={name}
      >
        {type === "select"
          ? options?.map((option) => (
              <option key={option.label} value={option.value}>
                {option.label}
              </option>
            ))
          : null}
      </Input>
    </FormGroup>
  )
}

export default FormikInput
